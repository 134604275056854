import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { CompanyGroupEntity } from "../../../companyGroup/domain/entities/companyGroupEntity";
import { useUserLocal } from "../../../core/presentation/hooks/useUserLocal";
import { ICompanyGroupEntity } from "../../domain/entities/companyGroupEntity";
import { MakeAdmin } from "../../main/makeAdmin";
import { SoulAnimation } from "../../../core/presentation/components/SoulAnimation";

const Loading = styled.div<{ shouldFadeOut: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 10000;
  position: fixed;
  align-items: center;
  justify-content: center;

  background-color: white;

  animation: fade-out 0.5s 1.5s ease-in forwards;

  animation-play-state: ${props =>
    props.shouldFadeOut ? "running" : "paused"};

  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }
`;

interface CompanyGroupProviderProps {
  admin: MakeAdmin;
  children: ReactNode;
}

interface ContextData {
  companyGroupList: ICompanyGroupEntity[];
  currentCompanyGroup: ICompanyGroupEntity;
  listCompanyGroups(): void;
  setCurrentCompanyGroup(companyGroup: ICompanyGroupEntity): void;
}

const CompanyGroupContext = createContext({} as ContextData);

export function CompanyGroupProvider({
  admin,
  children,
}: CompanyGroupProviderProps) {
  const {
    getCurrentCompanyGroup,
    setCurrentCompanyGroup: storeCurrentCompanyGroup,
    getCompanyGroupList,
  } = admin;

  const [showAnimation, setShowAnimation] = useState(true);
  const [loading, setLoading] = useState(true);
  const [companyGroupList, setCompanyGroupList] = useState<
    ICompanyGroupEntity[]
  >([]);

  const [currentCompanyGroup, setCurrentCompanyGroup] =
    useState<ICompanyGroupEntity>(() => {
      let curCompanyGroup = getCurrentCompanyGroup();

      if (!curCompanyGroup) {
        curCompanyGroup = CompanyGroupEntity.create() as ICompanyGroupEntity;
      }

      return curCompanyGroup;
    });

  const setCompanyGroupHandler = useCallback(
    (_currentCompanyGroup: ICompanyGroupEntity) => {
      localStorage.setItem("companyGroupId", _currentCompanyGroup.id);
      admin.api.setCustomHeaders?.({
        "Company-Group-Id": _currentCompanyGroup.id,
      });

      setCurrentCompanyGroup(_currentCompanyGroup);
      storeCurrentCompanyGroup(_currentCompanyGroup);
    },
    [admin.api, storeCurrentCompanyGroup],
  );

  const { user } = useUserLocal();

  const listCompanyGroups = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getCompanyGroupList(true, user.userId);

      setCompanyGroupList(res);
      if (res.length > 0 && currentCompanyGroup.id !== "") {
        const companyGroupResult = res.filter(
          _companyGroup => _companyGroup.id === currentCompanyGroup.id,
        );
        setCompanyGroupHandler(companyGroupResult[0]);
      } else if (res.length > 0) {
        setCompanyGroupHandler(res[0]);
      } else {
        setCompanyGroupHandler(
          CompanyGroupEntity.create() as ICompanyGroupEntity,
        );
      }
    } finally {
      setLoading(false);
    }
  }, [
    currentCompanyGroup.id,
    getCompanyGroupList,
    setCompanyGroupHandler,
    user.userId,
  ]);

  useEffect(() => {
    if (!(companyGroupList.length && currentCompanyGroup.id)) {
      listCompanyGroups();
    }
  }, [companyGroupList.length, currentCompanyGroup.id, listCompanyGroups]);

  useEffect(() => {
    const bodyElement = document.querySelector("body");

    if (bodyElement) {
      bodyElement.style.overflow = showAnimation ? "hidden" : "";
    }
  }, [showAnimation]);

  const value = useMemo(() => {
    return {
      companyGroupList,
      currentCompanyGroup,
      listCompanyGroups,
      setCurrentCompanyGroup: setCompanyGroupHandler,
    };
  }, [
    companyGroupList,
    currentCompanyGroup,
    listCompanyGroups,
    setCompanyGroupHandler,
  ]);

  return (
    <CompanyGroupContext.Provider value={value}>
      {showAnimation ? (
        <Loading
          shouldFadeOut={!loading}
          onAnimationEnd={e => {
            if (e.animationName !== "fade-out") return;
            setShowAnimation(false);
          }}
        >
          <SoulAnimation shouldAnimateLogo={!loading} />
        </Loading>
      ) : null}
      {loading ? null : children}
    </CompanyGroupContext.Provider>
  );
}

export function useCurrentCompanyGroup() {
  const context = useContext(CompanyGroupContext);

  return context;
}
