import { msalInstance } from "../../../auth/presentation/components/AuthProvider";
import { IUserEntity } from "../../domain/entities/userEntity";
import { IGetUserLocalService } from "../../domain/usecases/getUserLocalUseCase";
import { ELocalStorageKeys } from "../utils/localStorageKeys";

interface IDecryptService {
  decrypt(cyphertext: string): string;
}

export class GetUserLocalService implements IGetUserLocalService {
  userKey: string = ELocalStorageKeys.User;

  constructor(private decryptService: IDecryptService) {}

  get(): IUserEntity | null {
    const token = msalInstance.getActiveAccount()?.idToken;

    const encryptedData = localStorage.getItem(this.userKey);

    if (encryptedData && token) {
      const decryptedData = this.decryptService.decrypt(encryptedData);
      const userEntity: IUserEntity = JSON.parse(decryptedData);

      userEntity.token = token;

      return userEntity;
    }

    return null;
  }
}
