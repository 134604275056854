import { useCallback, useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../advTable/domain/entities/advTableColumn";
import { EEnumListBoxOptions } from "../../../../advTable/presentation/components/ColumnFilterEnum";
import { AsyncButton } from "../../../../core/presentation/components/AsyncButton";
import { ButtonDelete } from "../../../../core/presentation/components/ButtonDelete";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import {
  ISimpleColumn,
  SimpleColumn,
  SimpleTableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { PayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import {
  costCenterTransactionTypeLang,
  ECostCenterTransactionType,
  ICostCenterTransactionEntity,
} from "../../domain/entities/costCenterTransactionsEntity";
import { MakeCostCenterTransactions } from "../../main/makeCostCenterTransactions";
import { BadgeActive } from "../components/BadgeActive/styles";
import { BadgeType } from "../components/BadgeType/styles";
import { DeleteCostCenterTransactionsConfirm } from "../components/DeleteCostCenterTransactionsConfirm";

export enum EEditModalMode {
  edit,
  readonly,
}

interface IUseCostCenterTransactionsGridParams {
  useCostCenterTransactions: MakeCostCenterTransactions;
  openEditModal?(id: string, mode: EEditModalMode): void;
  toggle?(id: string): Promise<void> | void;
}

export function useCostCenterTransactionsGrid({
  useCostCenterTransactions,
  openEditModal,
  toggle,
}: IUseCostCenterTransactionsGridParams) {
  const dialog = useSoulDialog();
  const { listCostCenterTransactions } = useCostCenterTransactions;
  const {
    currentCompanyGroup: { id: selectedCompanyGroupId },
  } = useCurrentCompanyGroup();

  const fetchCostCenterTransactionsById = useCallback(
    async (id: string): Promise<ICostCenterTransactionEntity[]> => {
      const response = await listCostCenterTransactions(
        selectedCompanyGroupId,
        new PayloadEntity({
          columns: [
            {
              data: "id",
              searchable: true,
              name: "id",
              orderable: false,
              search: {
                regex: false,
                value: id,
              },
            },
            {
              data: "costCenterActive",
              name: "costCenterActive",
              orderable: false,
              searchable: false,
              search: { regex: false, value: "" },
            },
            {
              data: "type",
              name: "type",
              orderable: false,
              searchable: false,
              search: { regex: false, value: "" },
            },
            {
              data: "typeDescription",
              name: "typeDescription",
              orderable: false,
              searchable: false,
              search: { regex: false, value: "" },
            },
            {
              data: "value",
              name: "value",
              orderable: false,
              searchable: false,
              search: { regex: false, value: "" },
            },
            {
              data: "costCenterName",
              name: "costCenterName",
              orderable: false,
              searchable: false,
              search: { regex: false, value: "" },
            },
          ],
        }),
      );

      return response?.data;
    },
    [listCostCenterTransactions, selectedCompanyGroupId],
  );

  const hasCostCenterInactive = useCallback(
    async (transactions: ICostCenterTransactionEntity[]) => {
      return transactions.some(t => !t.costCenterActive);
    },
    [],
  );

  const editBodyTemplate = useCallback(
    ({ id, active }: ICostCenterTransactionEntity, { rowIndex }) => {
      const deleteId = `btn-del-${rowIndex}`;
      const editId = `btn-edit-${rowIndex}`;

      return (
        <div className="table-actionbar">
          <ButtonDelete
            id={deleteId}
            disabled={!active}
            className="outline-button tool"
            data-tip="Excluir movimentação"
            data-testid={deleteId}
            onClick={async () => {
              if (!active) {
                return;
              }

              const transactions = await fetchCostCenterTransactionsById(id);
              const isCostCenterInactive = await hasCostCenterInactive(
                transactions,
              );

              if (isCostCenterInactive) {
                await dialog.fire({
                  icon: "warning",
                  title: "Aviso",
                  text: `Não é possível excluir a movimentação pois o centro de
                  custo está inativo.`,
                });

                return;
              }

              const result = await dialog.fire({
                title: "Estas duas movimentações serão excluidas",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
                html: (
                  <DeleteCostCenterTransactionsConfirm
                    transactions={transactions}
                  />
                ),
              });

              if (result.dismiss) {
                return;
              }

              toggle?.(id);
            }}
          />
          <AsyncButton
            id={editId}
            type="button"
            disabled={!active}
            className="outline-button tool"
            data-tip="Editar movimentação"
            data-testid={editId}
            onClick={async () => {
              if (!active) {
                return;
              }

              const transactions = await fetchCostCenterTransactionsById(id);
              const isCostCenterInactive = await hasCostCenterInactive(
                transactions,
              );

              let mode = EEditModalMode.edit;

              if (isCostCenterInactive) {
                mode = EEditModalMode.readonly;

                const result = await dialog.fire({
                  icon: "question",
                  title: "Aviso",
                  showCancelButton: true,
                  cancelButtonText: "Não",
                  html: (
                    <>
                      Não é possível editar a movimentação pois o centro de
                      custo está inativo. <br />
                      Gostaria de visualizar a movimentação?
                    </>
                  ),
                });

                if (result.dismiss) {
                  return;
                }
              }

              openEditModal?.(id, mode);
            }}
          >
            <FaEdit />
          </AsyncButton>
        </div>
      );
    },
    [
      dialog,
      fetchCostCenterTransactionsById,
      hasCostCenterInactive,
      openEditModal,
      toggle,
    ],
  );

  const { searchCostCenters, searchUser } = useCostCenterTransactions;
  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Centro de Custo",
        field: "costCenterName",
        searchField: "costCenterId",
        searchable: true,
        orderable: true,
        className: "text-truncate",
        width: "12%",
        columnType: EColumnType.relationship,
        async getList(search = "", fetchMinLength = 10) {
          return searchCostCenters(id, search, fetchMinLength);
        },
      },
      {
        header: "Tipo",
        field: "typeDescription",
        searchable: false,
        searchField: "type",
        orderable: true,
        sortField: "type",
        className: "text-truncate",
        width: "10%",
        bodyTemplate(
          costCenterTransactionEntity: ICostCenterTransactionEntity,
        ) {
          const { type, typeDescription } = costCenterTransactionEntity;
          return (
            <BadgeType title={typeDescription} type={type}>
              {typeDescription}
            </BadgeType>
          );
        },
        columnType: EColumnType.enum,
        enumObject: ECostCenterTransactionType,
        enumLang: costCenterTransactionTypeLang,
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | ECostCenterTransactionType;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }
          const type = value as ECostCenterTransactionType;
          return (
            <BadgeType title={label} type={type}>
              {label}
            </BadgeType>
          );
        },
      },
      {
        header: "Valor",
        field: "valueString",
        sortField: "value",
        searchField: "value",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        className: "text-truncate",
        width: "9%",
      },
      {
        header: "Data da movimentação",
        field: "transactionDateString",
        searchable: true,
        searchField: "transactionDate",
        orderable: true,
        sortField: "transactionDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "11%",
      },
      {
        header: "Descrição",
        field: "description",
        searchable: true,
        orderable: true,
        width: "22%",
      } as ISimpleColumn,
      {
        header: "Usuário que movimentou",
        field: "userNameCreated",
        searchable: true,
        searchField: "userCreated",
        orderable: true,
        className: "text-truncate",
        columnType: EColumnType.relationship,
        width: "11%",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Última edição",
        field: "userNameModified",
        searchable: true,
        searchField: "userModified",
        orderable: true,
        className: "text-truncate",
        columnType: EColumnType.relationship,
        width: "11%",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "",
        field: "active",
        width: "7%",
        resizeable: false,
        bodyTemplate(
          costCenterTransactionEntity: ICostCenterTransactionEntity,
        ) {
          const { active } = costCenterTransactionEntity;
          const activeText = active ? "Ativo" : "Excluído";

          return (
            <BadgeActive title={activeText} active={active}>
              {activeText}
            </BadgeActive>
          );
        },
      },
      {
        header: "",
        width: "7%",
        resizeable: false,
        bodyTemplate: editBodyTemplate,
      },
      {
        hidden: true,
        field: "costCenterActive",
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [editBodyTemplate, id, searchCostCenters, searchUser]);

  return {
    columns,
  };
}
