import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { loginSSORequest, scopes } from "../../config/azureb2c";
import { IUserEntity } from "../../../core/domain/entities/userEntity";
import { useUserLocal } from "../../../core/presentation/hooks/useUserLocal";
import { UserB2cClaimsEntity } from "../../domain/entities/userEntity";

export function useAzureB2CAuth() {
  const { instance, inProgress } = useMsal();
  const { setUser } = useUserLocal();

  const login = useCallback(async () => {
    try {
      await instance.loginRedirect({
        ...loginSSORequest,
        prompt: "login",
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error("falha ao iniciar login: ", error);
    }
  }, [instance]);

  const refreshToken = useCallback(async () => {
    try {
      const activeAccount = instance.getActiveAccount();
      if (activeAccount) {
        await instance.acquireTokenSilent({
          account: activeAccount,
          scopes,
        });
        return;
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Erro na aquisição do token silenciosamente: ", error);
    }
  }, [instance]);

  const logout = useCallback(async () => {
    try {
      const account = instance.getActiveAccount();
      if (account) {
        await instance.logoutRedirect({ account });

        setUser({
          userId: "",
          name: "",
          email: "",
          active: false,
          profile: 0,
          token: "",
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("Erro na tentativa de logout.");
    }
  }, [instance, setUser]);

  const logoutWhithoutAccount = useCallback(async () => {
    await instance.logout();
  }, [instance]);

  const mapUserFromClaims = useCallback(
    (claims: UserB2cClaimsEntity, token: string): IUserEntity => {
      return {
        userId: claims?.sub || "",
        name: claims?.name || "",
        email: String(claims.emails?.[0] || ""),
        active: claims?.extension_Active || false,
        profile: Number(claims?.jobTitle) || 0,
        token: token || "",
      };
    },
    [],
  );

  const isAuthenticated = useCallback(() => {
    return !!instance.getActiveAccount();
  }, [instance]);

  const handleAuthRedirect = useCallback(async () => {
    try {
      const result = await instance.handleRedirectPromise();
      const activeAccount = instance.getActiveAccount();

      const claims =
        activeAccount?.idTokenClaims as unknown as UserB2cClaimsEntity;
      const token = activeAccount?.idToken as string;

      if (result) {
        return {
          success: true,
          isAuthenticated: true,
          user: mapUserFromClaims(claims, token),
        };
      }

      if (activeAccount) {
        await refreshToken();

        return {
          success: true,
          isAuthenticated: true,
          user: mapUserFromClaims(claims, token),
        };
      }

      return {
        success: true,
        isAuthenticated: false,
        user: null,
      };
    } catch (error) {
      // eslint-disable-next-line
      console.error("Erro de redirecionamento de autenticação:", error);

      return {
        success: false,
        isAuthenticated: false,
        user: null,
      };
    }
  }, [instance, mapUserFromClaims, refreshToken]);

  return {
    login,
    logout,
    logoutWhithoutAccount,
    refreshToken,
    handleAuthRedirect,
    isAuthenticated,
    inProgress,
  };
}
