import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResetPasswordB2cContract } from "../../domain/contracts/resetPasswordB2cContract";
import { IUserEntity, UserEntity } from "../../domain/entities/userEntity";

export class ResetPasswordB2cService implements IResetPasswordB2cContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async resetPasswordB2c(id: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/UsersB2C/External/ResetPassword/${id}`;

    const response = await this.api.post<IUserEntity>(url, undefined, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new UserEntity(response);
  }
}
