import axios, { AxiosDefaults, AxiosInstance, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import { IErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";

export type HttpResponseType = AxiosResponse;

export interface IApiService {
  get<T>(url: string, config?: Record<string, unknown>): Promise<T>;
  post<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T>;
  put<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T>;
  patch<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T>;
  delete<T>(url: string, config?: Record<string, unknown>): Promise<T>;
  // TODO retirar marcador opcional deste método
  // após corrigir todos os mocks dos testes
  download?(
    url: string,
    config?: Record<string, unknown>,
  ): Promise<HttpResponseType>;
  // TODO retirar marcador opcional deste método
  // após corrigir todos os mocks dos testes
  postAndDownload?(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<HttpResponseType>;
  setCustomHeaders?(headers: Record<string, string>): void;
}

export interface IApiErrorResponse<T> {
  data: T;
  status: StatusCodes;
  statusText: string;
}

export interface IApiError<T = unknown> {
  config: {
    headers?: Record<string, string>;
  };
  response: IApiErrorResponse<T>;
}

export class ApiError<T = unknown> implements IApiError<T> {
  config = {};
  response = {
    data: null as unknown as T,
    status: StatusCodes.OK,
    statusText: "OK",
  };

  constructor(init?: Partial<IApiError<T>>) {
    Object.assign(this, init);
  }
}

export type ApiResponseErrorHandlersType = Partial<
  Record<
    StatusCodes | 9999,
    (
      error?: IErrorResponseEntity,
      errorData?: IApiError,
      axiosDefaults?: AxiosDefaults,
    ) => unknown | Promise<unknown>
  >
>;

export class ApiService implements IApiService {
  private axiosInstance: AxiosInstance;

  constructor(
    baseURL: string,
    private responseErrorHandlers?: ApiResponseErrorHandlersType,
  ) {
    this.axiosInstance = axios.create({ baseURL });

    // NOTE enquanto o site estiver atendendo apenas usuários da lingua portuguesa,
    // é viável manter essa configuração de forma global no projeto.
    this.axiosInstance.interceptors.request.use(request => {
      request.headers = request.headers ?? {};

      const companyGroupId = localStorage.getItem("companyGroupId");
      if (companyGroupId) {
        request.headers["Company-Group-Id"] = companyGroupId;
      }

      request.headers["Accept-Language"] = "pt-br";

      return request;
    });

    this.axiosInstance.interceptors.response.use(
      undefined,
      async (error: IApiError<IErrorResponseEntity>) => {
        const status = error?.response?.status;

        const errorHandler = this.responseErrorHandlers?.[status];
        let result;

        if (errorHandler) {
          const errorResponseEntity = error?.response?.data;

          result = await errorHandler(
            errorResponseEntity,
            error,
            this.axiosInstance?.defaults,
          );
        } else {
          // eslint-disable-next-line no-console
          console.log(error);
          this.responseErrorHandlers?.[9999]?.();
        }

        if (result) {
          return result;
        }

        return Promise.reject(error);
      },
    );
  }

  async get<T>(url: string, config?: Record<string, unknown>): Promise<T> {
    const axiosResponse = await this.axiosInstance.get<T>(url, config);
    return axiosResponse.data;
  }

  async post<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T> {
    const axiosResponse = await this.axiosInstance.post(url, data, config);
    return axiosResponse.data;
  }

  async put<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T> {
    const axiosResponse = await this.axiosInstance.put(url, data, config);
    return axiosResponse.data;
  }

  async patch<T>(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<T> {
    const axiosResponse = await this.axiosInstance.patch(url, data, config);
    return axiosResponse.data;
  }

  async delete<T>(url: string, config?: Record<string, unknown>): Promise<T> {
    const axiosResponse = await this.axiosInstance.delete(url, config);
    return axiosResponse.data;
  }

  async download?(
    url: string,
    config?: Record<string, unknown>,
  ): Promise<HttpResponseType> {
    const axiosResponse = await this.axiosInstance.get(url, config);
    return axiosResponse;
  }

  async postAndDownload?(
    url: string,
    data: unknown,
    config?: Record<string, unknown>,
  ): Promise<HttpResponseType> {
    const axiosResponse = await this.axiosInstance.post(url, data, config);
    return axiosResponse;
  }

  setCustomHeaders(headers: Record<string, string>): void {
    const axiosDefaults = this.axiosInstance?.defaults;

    if (axiosDefaults) {
      Object.assign(axiosDefaults.headers.common, headers);
    }
  }
}
