import { IAuthUserModel } from "../../../core/data/models/authUserModel";
import {
  IServerSideResponseModel,
  ServerSideResponseModel,
} from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { AuthUserEntity } from "../../../core/domain/entities/authUserEntity";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import {
  IListUsersB2cContract,
  IListUsersB2cContractParams,
} from "../../domain/contracts/listUsersB2cContract";

export class ListUsersB2cService implements IListUsersB2cContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listUsersB2c({
    payload,
    isSupervisor,
    actives,
  }: IListUsersB2cContractParams) {
    const url = isSupervisor
      ? "/UsersB2C/GetAllUsers/Supervisor"
      : "/UsersB2C/GetAllUsers";

    const userEntity = this.getUserLocalService.get();

    const endpoint = actives ? `${url}/Actives` : url;

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const finalEndpoint = `${endpoint}?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IAuthUserModel[]>
    >(finalEndpoint, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return new ServerSideResponseModel({
      ...response,
      data: response.data.map(
        user =>
          new AuthUserEntity({
            id: user.id || "",
            active: !!user.active,
            name: user.name || "",
            email: user.email || "",
            profile: user.profile || 0,
            partnerId: user.partnerId || "",
            profileDescription: user.profileDescription || "",
            isExternalUser: user.isExternalUser || false,
          }),
      ),
    });
  }
}
