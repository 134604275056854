import { useCallback, useMemo } from "react";
import { FaEdit, FaKey } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IAuthUserEntity } from "../../../core/domain/entities/authUserEntity";

interface UseUserGridParams {
  openEditModal?(id: string): void;
  resetPassword?(id: string): void;
  toggle?(id: string, toActivate: boolean): Promise<void> | void;
}

export function useUserGrid({
  openEditModal,
  resetPassword,
  toggle,
}: UseUserGridParams) {
  const editBodyTemplate = useCallback(
    ({ id, isExternalUser }: IAuthUserEntity, { rowIndex }) => {
      return (
        <div className="table-actionbar">
          <button
            data-tip="Redefinir senha"
            type="button"
            className="outline-button tool"
            id={`btn-password-reset-${rowIndex}`}
            data-testid={`btn-password-reset-${rowIndex}`}
            disabled={!isExternalUser}
            onClick={() => {
              resetPassword?.(id);
            }}
          >
            <FaKey />
          </button>
          <button
            data-tip="Editar usuário"
            type="button"
            className="outline-button tool"
            id={`btn-edit-${rowIndex}`}
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              openEditModal?.(id);
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [openEditModal, resetPassword],
  );

  const dialog = useSoulDialog();

  const activeBodyTemplate = useCallback(
    ({ id, active }: IAuthUserEntity, { rowIndex }) => {
      const props: Record<string, string> = {};

      return (
        <div {...props}>
          <Toggle
            value={active}
            inactiveClassName="toggle-muted"
            id={`btn-tgl-status-${rowIndex}`}
            data-testid={`btn-tgl-status-${rowIndex}`}
            onChange={async _active => {
              const text = !_active ? "inativado" : "ativado";
              const result = await dialog.fire({
                icon: "question",
                title: "Você está certo disso?",
                html: `O Usuário será ${text}. <br /> Deseja prosseguir?`,
                showCancelButton: true,
                cancelButtonText: "Não",
                async preConfirm() {
                  dialog.update({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  });
                  dialog.showLoading();

                  return toggle?.(id, _active);
                },
              });

              if (result.isConfirmed) {
                return _active;
              }

              return !_active;
            }}
          />
        </div>
      );
    },
    [dialog, toggle],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Nome",
        field: "name",
        orderable: true,
        searchable: true,
      },
      {
        header: "e-mail",
        field: "email",
        searchable: false,
        orderable: false,
        className: "text-truncate",
      },
      {
        header: "Perfil",
        field: "profileDescription",
        searchable: false,
        orderable: true,
      },
      {
        header: "",
        bodyTemplate: editBodyTemplate,
      },
      {
        field: "active",
        header: "Ativo",
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "profile",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
