import { ReactNode, useEffect, useState } from "react";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { toast } from "react-toastify";
import { msalConfig } from "../../../config/azureb2c";
import { Loading } from "../Loading/styles";
import { SoulAnimation } from "../../../../core/presentation/components/SoulAnimation";

interface AuthProviderProps {
  children: ReactNode;
}

export const msalInstance = new PublicClientApplication(msalConfig);

export function AuthProvider({ children }: AuthProviderProps) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();

        if (
          !msalInstance.getActiveAccount() &&
          msalInstance.getAllAccounts().length > 0
        ) {
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }

        setIsInitialized(true);
      } catch (error) {
        // eslint-disable-next-line
        console.error("Erro ao inicializar MSAL:", error);
        toast.error("Erro na inicialização do autenticador.");
      }
    };

    initializeMsal();

    const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload
      ) {
        const { account } = event.payload as AuthenticationResult;
        msalInstance.setActiveAccount(account);
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  if (!isInitialized) {
    return (
      <Loading
        shouldFadeOut={isInitialized}
        onAnimationEnd={e => {
          if (e.animationName !== "fade-out") return;
          setIsInitialized(false);
        }}
      >
        <SoulAnimation shouldAnimateLogo={isInitialized} />
      </Loading>
    );
  }

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
