import { IApiService } from "../../../core/data/services/apiService";
import {
  EUserProfile,
  userProfileDictionary,
} from "../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateUserB2cContract } from "../../domain/contracts/updateUserB2cContract";
import { departmentDestinationProfiles } from "../../domain/entities/departmentDestinationProfiles";
import { IUserEntity, UserEntity } from "../../domain/entities/userEntity";
import { UserModel, UserRequestProfilesModel } from "../models/userModel";

export class UpdateUserB2cService implements IUpdateUserB2cContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async updateUserB2c(payload: IUserEntity): Promise<IUserEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = "/UsersB2C/UpdateUser";

    const userModel = new UserModel({
      id: payload.id,
      name: payload.name,
      email: payload.email,
      document: payload.document.replace(/\D/g, ""),
      password: payload.password,
      passwordCheck: payload.password,
      profile: Number(payload.profile),
      profileDescription:
        userProfileDictionary[payload.profile as EUserProfile],
    });

    const response = await this.api.put<IUserEntity>(url, userModel, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    if (departmentDestinationProfiles.includes(userModel.profile)) {
      const usrRequestProfilesUrl = `/Users/${response?.id}/UserRequestProfiles`;

      const usrRequestProfilesData = UserRequestProfilesModel.create({
        isFinancial: payload.isFinancial,
        isPersonnelDepartment: payload.isPersonnelDepartment,
        userProfile: Number(payload.profile),
      });

      await this.api.post(usrRequestProfilesUrl, usrRequestProfilesData, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      });
    }

    return new UserEntity(response);
  }
}
