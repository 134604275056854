import { ParsePanoramaAngularService } from "../../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../../advTable/data/services/transformPanoramaAngularService";
import { AdvGeneratePayloadExportUseCase } from "../../../../advTable/domain/usecases/advGeneratePayloadExportUseCase";
import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { FetchCommercialProposalEnums } from "../../../../core/data/services/fetchCommercialProposalEnumsService";
import { FetchRpaParamOptionsService } from "../../../../core/data/services/fetchRpaParamOptionsService";
import { GenerateCommercialProposalAttachmentService } from "../../../../core/data/services/generateCommercialProposalAttachmentService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { ListAttachmentTypesService } from "../../../../core/data/services/listAttachmentTypesService";
import { SearchClassificationAccountService } from "../../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../../core/data/services/searchCostCenterService";
import { SearchPaymentAccountService } from "../../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SearchProviderService } from "../../../../core/data/services/searchProviderService";
import { SearchUserService } from "../../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { IFetchCommercialProposalEnumsContract } from "../../../../core/domain/contracts/fetchCommercialProposalEnumsContract";
import { IFetchRpaParamOptionsContract } from "../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { IGenerateCommercialProposalAttachmentContract } from "../../../../core/domain/contracts/generateCommercialProposalAttachmentContract";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchClassificationAccountContract } from "../../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../../core/domain/contracts/searchCostCenterContract";
import { ISearchPaymentAccountContract } from "../../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { ISearchProviderContract } from "../../../../core/domain/contracts/searchProviderContract";
import { ISearchUserContract } from "../../../../core/domain/contracts/searchUserContract";
import { BulkTerminateAccountService } from "../data/services/bulkTerminateAccountsService";
import { CancelAccountPayableService } from "../data/services/cancelAccountPayableService";
import { DeleteAccountsPayablePanoramaService } from "../data/services/deleteAccountsPayablePanoramaService";
import { DeleteGeneratedFeesService } from "../data/services/deleteGeneratedFeesService";
import { ExportAccountsPayableService } from "../data/services/exportAccountsPayableService";
import { ExportSheetFealqService } from "../data/services/exportSheetFealqService";
import { ExportSheetFuspService } from "../data/services/exportSheetFuspService";
import { FetchAccountPayableAssessmentListService } from "../data/services/fetchAccountPayableAssessmentListService";
import { FetchLastFealqSheetExportedService } from "../data/services/fetchLastFealqSheetExportedService";
import { FetchLastFuspSheetExportedService } from "../data/services/fetchLastFuspSheetExportedService";
import { GenerateAttachmentRpaService } from "../data/services/generateAttachmentRpaService";
import { GenerateAttachmentVariableAdditionalService } from "../data/services/generateAttachmentVariableAdditionalService";
import { GenerateFeeRetentionService } from "../data/services/generateFeeRetentionService";
import { GeneratePaymentToSuppliersRemittanceFileService } from "../data/services/generatePaymentToSuppliersRemittanceFileService";
import { GeneratePayrollRemittanceFileService } from "../data/services/generatePayrollRemittanceService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetFeeReportService } from "../data/services/getFeeReportService";
import { GetProviderRemittanceTemplateService } from "../data/services/getProviderRemittanceTemplateService";
import { GetSafraPayrollRemittanceTemplateService } from "../data/services/getSafraPayrollRemittanceTemplateService";
import { GetSafraProviderRemittanceTemplateService } from "../data/services/getSafraProviderRemittanceTemplateService";
import { GetSafraTaxesRemittanceTemplateService } from "../data/services/getSafraTaxesRemittanceTemplateService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { GetStoredAccountsPayablePanoramaIdService } from "../data/services/getStoredAccountsPayablePanoramaIdService";
import { GetTaxesRemittanceTemplateService } from "../data/services/getTaxesRemittanceTemplateService";
import { ImportProviderRemittanceFileService } from "../data/services/importProviderRemittanceFileService";
import { ImportSafraPayrollRemittanceFileService } from "../data/services/importSafraPayrollRemittanceFileService";
import { ImportSafraProviderRemittanceFileService } from "../data/services/importSafraProviderRemittanceFileService";
import { ImportSafraTaxesRemittanceFileService } from "../data/services/importSafraTaxesRemittanceFileService";
import { ImportTaxesRemittanceFileService } from "../data/services/importTaxesRemittanceFileService";
import { ListAccountPayableFeesService } from "../data/services/listAccountPayableFeesService";
import { ListAccountsPayablePanoramasService } from "../data/services/listAccountsPayablePanoramasService";
import { ListAccountsPayableService } from "../data/services/listAccountsPayableService";
import { ListExportedFuspAccountsService } from "../data/services/listExportedFuspAccountsService";
import { RemoveBulkTerminateAccountsService } from "../data/services/removeBulkTerminateAccountsService";
import { RemoveTerminationService } from "../data/services/removeTerminationService";
import { ReturnAccountPayableService } from "../data/services/returnAccountPayableService";
import { SaveAccountsPayablePanoramaService } from "../data/services/saveAccountsPayablePanoramaService";
import { SendAccountPayableToFuspByIdsService } from "../data/services/sendAccountPayableToFuspByIdsService";
import { SendAccountPayableToFuspService } from "../data/services/sendAccountPayableToFuspService";
import { StoreAccountsPayablePanoamaIdService } from "../data/services/storeAccountsPayablePanoamaIdService";
import { TerminateAccountService } from "../data/services/terminateAccountService";
import { UndoReturnAccountPayableService } from "../data/services/undoReturnAccountPayableService";
import { UpdateAttachmentsBarcodeService } from "../data/services/updateAttachmentsBarcodeService";
import { UploadAttachmentService } from "../data/services/uploadAttachmentService";
import { VerifyHasGeneratedRemittancesService } from "../data/services/verifyHasGeneratedRemittancesService";
import { IBulkTerminateAccountsContract } from "../domain/contracts/bulkTerminateAccountsContract";
import { ICancelAccountPayableContract } from "../domain/contracts/cancelAccountPayableContract";
import { IDeleteAccountsPayablePanoramaContract } from "../domain/contracts/deleteAccountsPayablePanoramaContract";
import { IDeleteGeneratedFeesContract } from "../domain/contracts/deleteGeneratedFeesContract";
import { IExportAccountsPayableContract } from "../domain/contracts/exportAccountsPayableContract";
import { IExportSheetFealqContract } from "../domain/contracts/exportSheetFealqContract";
import { IExportSheetFuspContract } from "../domain/contracts/exportSheetFuspContract";
import { IFetchAccountPayableAssessmentListContract } from "../domain/contracts/fetchAccountPayableAssessmentListContract";
import { IFetchLastFealqSheetExportedContract } from "../domain/contracts/fetchLastFealqSheetExportedContract";
import { IFetchLastFuspSheetExportedContract } from "../domain/contracts/fetchLastFuspSheetExportedContract";
import { IGenerateAttachmentRpaContract } from "../domain/contracts/generateAttachmentRpaContract";
import { IGenerateAttachmentVariableAdditionalContract } from "../domain/contracts/generateAttachmentVariableAdditionalContract";
import { IGenerateFeeRetentionContract } from "../domain/contracts/generateFeeRetentionContract";
import { IGeneratePaymentToSuppliersRemittanceFileContract } from "../domain/contracts/generatePaymentToSuppliersRemittanceFileContract";
import { IGeneratePayrollRemittanceFileContract } from "../domain/contracts/generatePayrollRemittanceContract";
import { IGetAttachmentsContract } from "../domain/contracts/getAttachmentsContract";
import { IGetFeeReportContract } from "../domain/contracts/getFeeReportContract";
import { IGetProviderRemittanceTemplateContract } from "../domain/contracts/getProviderRemittanceTemplateContract";
import { IGetSafraPayrollRemittanceTemplateContract } from "../domain/contracts/getSafraPayrollRemittanceTemplateContract";
import { IGetSafraProviderRemittanceTemplateContract } from "../domain/contracts/getSafraProviderRemittanceTemplateContract";
import { IGetSafraTaxesRemittanceTemplateContract } from "../domain/contracts/getSafraTaxesRemittanceTemplateContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IGetStoredAccountsPayablePanoramaIdContract } from "../domain/contracts/getStoredAccountsPayablePanoramaIdContract";
import { IGetTaxesRemittanceTemplateContract } from "../domain/contracts/getTaxesRemittanceTemplateContract";
import { IImportProviderRemittanceFileContract } from "../domain/contracts/importProviderRemittanceFileContract";
import { IImportSafraPayrollRemittanceFileContract } from "../domain/contracts/importSafraPayrollRemittanceFileContract";
import { IImportSafraProviderRemittanceFileContract } from "../domain/contracts/importSafraProviderRemittanceFileContract";
import { IImportSafraTaxesRemittanceFileContract } from "../domain/contracts/importSafraTaxesRemittanceFileContract";
import { IImportTaxesRemittanceFileContract } from "../domain/contracts/importTaxesRemittanceFileContract";
import { IListAccountPayableFeesContract } from "../domain/contracts/listAccountPayableFeesContract";
import { IListAccountsPayableContract } from "../domain/contracts/listAccountsPayableContract";
import { IListAccountsPayablePanoramasContract } from "../domain/contracts/listAccountsPayablePanoramasContract";
import { IListExportedFuspAccountsContract } from "../domain/contracts/listExportedFuspAccountsContract";
import { IRemoveBulkTerminateAccountsContract } from "../domain/contracts/removeBulkTerminateAccountsContract";
import { IRemoveTerminationContract } from "../domain/contracts/removeTerminationContract";
import { IReturnAccountPayableContract } from "../domain/contracts/returnAccountPayableContract";
import { ISaveAccountsPayablePanoramaContract } from "../domain/contracts/saveAccountsPayablePanoramaContract";
import { ISendAccountPayableToFuspByIdsContract } from "../domain/contracts/sendAccountPayableToFuspByIdsContract";
import { ISendAccountPayableToFuspContract } from "../domain/contracts/sendAccountPayableToFuspContract";
import { IStoreAccountsPayablePanoamaIdContract } from "../domain/contracts/storeAccountsPayablePanoamaIdContract";
import { ITerminateAccountContract } from "../domain/contracts/terminateAccountContract";
import { IUndoReturnAccountPayableContract } from "../domain/contracts/undoReturnAccountPayableContract";
import { IUpdateAttachmentsBarcodeContract } from "../domain/contracts/updateAttachmentsBarcodeContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";
import { IVerifyHasGeneratedRemittancesContract } from "../domain/contracts/verifyHasGeneratedRemittancesContract";

export type MakeAccountsPayable = IListAccountsPayableContract &
  IGetStoredAccountsPayablePanoramaIdContract &
  IListAccountsPayablePanoramasContract &
  ISaveAccountsPayablePanoramaContract &
  IDeleteAccountsPayablePanoramaContract &
  IRemoveTerminationContract &
  ICancelAccountPayableContract &
  IExportAccountsPayableContract &
  IUndoReturnAccountPayableContract &
  IReturnAccountPayableContract &
  IFetchLastFealqSheetExportedContract &
  IExportSheetFealqContract &
  IFetchLastFuspSheetExportedContract &
  IExportSheetFuspContract &
  IListExportedFuspAccountsContract &
  IFetchAccountPayableAssessmentListContract &
  IGetAttachmentsContract &
  IGetStorageFilebyIdContract &
  IListAttachmentTypesContract &
  IUploadAttachmentsContract &
  IUpdateAttachmentsBarcodeContract &
  ITerminateAccountContract &
  IBulkTerminateAccountsContract &
  IStoreAccountsPayablePanoamaIdContract &
  IGetFeeReportContract &
  IGetTaxesRemittanceTemplateContract &
  IGetProviderRemittanceTemplateContract &
  IImportTaxesRemittanceFileContract &
  IImportProviderRemittanceFileContract &
  IGeneratePayrollRemittanceFileContract &
  IGetSafraPayrollRemittanceTemplateContract &
  IImportSafraPayrollRemittanceFileContract &
  IGenerateAttachmentRpaContract &
  IGenerateAttachmentVariableAdditionalContract &
  IFetchRpaParamOptionsContract &
  IListAccountPayableFeesContract &
  IDeleteGeneratedFeesContract &
  IGenerateFeeRetentionContract &
  IGeneratePayrollRemittanceFileContract &
  IGetSafraPayrollRemittanceTemplateContract &
  ISendAccountPayableToFuspByIdsContract &
  ISendAccountPayableToFuspContract &
  IImportSafraPayrollRemittanceFileContract &
  IGetSafraProviderRemittanceTemplateContract &
  IImportSafraProviderRemittanceFileContract &
  IGetSafraTaxesRemittanceTemplateContract &
  IImportSafraTaxesRemittanceFileContract &
  ISearchClassificationAccountContract &
  ISearchClassificationUspContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchPaymentAccountContract &
  ISearchProjectContract &
  ISearchUserContract &
  ISearchProviderContract &
  IRemoveBulkTerminateAccountsContract &
  IFetchCommercialProposalEnumsContract &
  IGenerateCommercialProposalAttachmentContract &
  IGeneratePaymentToSuppliersRemittanceFileContract &
  IVerifyHasGeneratedRemittancesContract;

export function makeAccountsPayable(
  api: IApiService,
  authApi: IApiService,
  barcodeApi: IApiService,
): MakeAccountsPayable {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listAccountsPayableSvc = new ListAccountsPayableService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const storeAccountsPayablePanoamaIdSvc =
    new StoreAccountsPayablePanoamaIdService();

  const parsePanoramaAngularSvc = new ParsePanoramaAngularService();

  const listAccountsPayablePanoramasSvc =
    new ListAccountsPayablePanoramasService(
      parsePanoramaAngularSvc,
      getUserLocalService,
      api,
    );

  const getStoredAccountsPayablePanoramaIdSvc =
    new GetStoredAccountsPayablePanoramaIdService();

  const transformPanoramaAngularSvc = new TransformPanoramaAngularService();
  const saveAccountsPayablePanoSvc = new SaveAccountsPayablePanoramaService(
    transformPanoramaAngularSvc,
    getUserLocalService,
    api,
  );

  const removeTerminationSvc = new RemoveTerminationService(
    getUserLocalService,
    api,
  );

  const delAccountsPayablePanoramaSvc =
    new DeleteAccountsPayablePanoramaService(getUserLocalService, api);

  const cancelAccountPayableSvc = new CancelAccountPayableService(
    getUserLocalService,
    api,
  );

  const advGeneratePayloadExportUseCase = new AdvGeneratePayloadExportUseCase();

  const exportAccountsPayableSvc = new ExportAccountsPayableService(
    advGeneratePayloadExportUseCase,
    setServerSideService,
    getUserLocalService,
    api,
  );

  const undoReturnAccountPayableSvc = new UndoReturnAccountPayableService(
    getUserLocalService,
    api,
  );

  const returnAccountPayableSvc = new ReturnAccountPayableService(
    getUserLocalService,
    api,
  );

  const fetchLastFealqSheetExportedSvc = new FetchLastFealqSheetExportedService(
    getUserLocalService,
    api,
  );

  const exportSheetFealqSvc = new ExportSheetFealqService(
    getUserLocalService,
    api,
  );

  const FetchAccountPayableAssessmentListSvc =
    new FetchAccountPayableAssessmentListService(getUserLocalService, api);

  const getAttachmentsSvc = new GetAttachmentsService(getUserLocalService, api);

  const getStorageFilebyIdSvc = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listAttachmentTypesSvc = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const uploadAttachmentSvc = new UploadAttachmentService(
    getUserLocalService,
    api,
  );

  const updtAttachmentsBarcodeSvc = new UpdateAttachmentsBarcodeService(
    getUserLocalService,
    api,
    barcodeApi,
  );

  const terminateAccountSvc = new TerminateAccountService(
    getUserLocalService,
    api,
  );

  const bulkTerminateAccountSvc = new BulkTerminateAccountService(
    getUserLocalService,
    api,
  );

  const getFeeReportService = new GetFeeReportService(getUserLocalService, api);

  const getTaxesRemittanceTemplateService =
    new GetTaxesRemittanceTemplateService(getUserLocalService, api);

  const getProviderRemittanceTemplateService =
    new GetProviderRemittanceTemplateService(getUserLocalService, api);

  const importTaxesRemittanceFileService = new ImportTaxesRemittanceFileService(
    getUserLocalService,
    api,
  );

  const importProviderRemittanceFileService =
    new ImportProviderRemittanceFileService(getUserLocalService, api);

  const generatePayrollRemittanceFileService =
    new GeneratePayrollRemittanceFileService(getUserLocalService, api);

  const genAttachmentRpaSvc = new GenerateAttachmentRpaService(
    getUserLocalService,
    api,
  );

  const genAttachmentVariableAdditionalvc =
    new GenerateAttachmentVariableAdditionalService(getUserLocalService, api);

  const fetchRpaParamOptionsSvc = new FetchRpaParamOptionsService(
    getUserLocalService,
    api,
  );

  const listAccountPayableFeesSvc = new ListAccountPayableFeesService(
    getUserLocalService,
    api,
  );

  const delGeneratedFeesSvc = new DeleteGeneratedFeesService(
    getUserLocalService,
    api,
  );

  const genFeeRetentionSvc = new GenerateFeeRetentionService(
    getUserLocalService,
    api,
  );

  const getSafraPayrollRemittanceTemplateService =
    new GetSafraPayrollRemittanceTemplateService(getUserLocalService, api);

  const importSafraPayrollRemittanceFileService =
    new ImportSafraPayrollRemittanceFileService(getUserLocalService, api);

  const fetchLastFuspSheetExportedSvc = new FetchLastFuspSheetExportedService(
    getUserLocalService,
    api,
  );

  const exportSheetFuspSvc = new ExportSheetFuspService(
    getUserLocalService,
    api,
  );

  const listExportedFuspAccountsSvc = new ListExportedFuspAccountsService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const sendAccountPayableToFuspByIdsSvc =
    new SendAccountPayableToFuspByIdsService(getUserLocalService, api);

  const sendAccountPayableToFuspSvc = new SendAccountPayableToFuspService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const importSafraProviderFileService =
    new ImportSafraProviderRemittanceFileService(getUserLocalService, api);

  const getSafraProviderRemittanceTemplateService =
    new GetSafraProviderRemittanceTemplateService(getUserLocalService, api);

  const importSafraTaxesFileService = new ImportSafraTaxesRemittanceFileService(
    getUserLocalService,
    api,
  );

  const getSafraTaxesRemittanceTemplateService =
    new GetSafraTaxesRemittanceTemplateService(getUserLocalService, api);

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, api);

  const searchProviderService = new SearchProviderService(
    getUserLocalService,
    api,
  );

  const removeBulkTerminateAccountsService =
    new RemoveBulkTerminateAccountsService(getUserLocalService, api);

  const fetchCommercialProposalEnumsService = new FetchCommercialProposalEnums(
    getUserLocalService,
    api,
  );

  const generateCommercialProposalAttachmentService =
    new GenerateCommercialProposalAttachmentService(getUserLocalService, api);

  const generatePaymentToSuppliersRemittanceFileService =
    new GeneratePaymentToSuppliersRemittanceFileService(
      getUserLocalService,
      api,
    );

  const verifyHasGeneratedRemittancesService =
    new VerifyHasGeneratedRemittancesService(api, getUserLocalService);

  return {
    listAccountsPayable(companyGroupId, payload) {
      return listAccountsPayableSvc.listAccountsPayable(
        companyGroupId,
        payload,
      );
    },
    storeAccountsPayablePanoamaId(panoramaId) {
      storeAccountsPayablePanoamaIdSvc.storeAccountsPayablePanoamaId(
        panoramaId,
      );
    },
    getStoredAccountsPayablePanoramaId() {
      return getStoredAccountsPayablePanoramaIdSvc.getStoredAccountsPayablePanoramaId();
    },
    listAccountsPayablePanoramas(userId, columns) {
      return listAccountsPayablePanoramasSvc.listAccountsPayablePanoramas(
        userId,
        columns,
      );
    },
    saveAccountsPayablePanorama(columns, panorama) {
      return saveAccountsPayablePanoSvc.saveAccountsPayablePanorama(
        columns,
        panorama,
      );
    },
    deleteAccountsPayablePanorama(panoramaId) {
      return delAccountsPayablePanoramaSvc.deleteAccountsPayablePanorama(
        panoramaId,
      );
    },
    removeTermination(companyGroupId, accountPayableId) {
      return removeTerminationSvc.removeTermination(
        companyGroupId,
        accountPayableId,
      );
    },
    cancelAccountPayableContract(accountPayableId, reason) {
      return cancelAccountPayableSvc.cancelAccountPayableContract(
        accountPayableId,
        reason,
      );
    },
    exportAccountsPayable(
      currentCompanyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColNames,
    ) {
      return exportAccountsPayableSvc.exportAccountsPayable(
        currentCompanyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColNames,
      );
    },
    undoReturn(companyGroupId, accountPayableId) {
      return undoReturnAccountPayableSvc.undoReturn(
        companyGroupId,
        accountPayableId,
      );
    },
    returnAccountPayable(accountPayableId, returnDate, costCenterLinks) {
      return returnAccountPayableSvc.returnAccountPayable(
        accountPayableId,
        returnDate,
        costCenterLinks,
      );
    },

    fetchLastFealqSheetExported() {
      return fetchLastFealqSheetExportedSvc.fetchLastFealqSheetExported();
    },
    exportSheetFealq(exportSheeFealqEntity) {
      return exportSheetFealqSvc.exportSheetFealq(exportSheeFealqEntity);
    },
    fetchAccountPayableAssessmentLiist(accountPayableId) {
      return FetchAccountPayableAssessmentListSvc.fetchAccountPayableAssessmentLiist(
        accountPayableId,
      );
    },
    getAttachments(accountPayableListItem) {
      return getAttachmentsSvc.getAttachments(accountPayableListItem);
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdSvc.getStorageFilebyId(storageFileId);
    },
    listAttachmentTypes() {
      return listAttachmentTypesSvc.listAttachmentTypes();
    },
    uploadAttachments(
      accountPayableId,
      attachmentList,
      uploadProgressCallback,
    ) {
      return uploadAttachmentSvc.uploadAttachments(
        accountPayableId,
        attachmentList,
        uploadProgressCallback,
      );
    },
    updateAttachmentsBarcode(companyGroupId, attachmentFormInput) {
      return updtAttachmentsBarcodeSvc.updateAttachmentsBarcode(
        companyGroupId,
        attachmentFormInput,
      );
    },
    terminateAccount(params) {
      return terminateAccountSvc.terminateAccount(params);
    },
    bulkTerminateAccounts(params) {
      return bulkTerminateAccountSvc.bulkTerminateAccounts(params);
    },
    getFeeReport(values, companyGroupId, handleDownloadProgress) {
      return getFeeReportService.getFeeReport(
        values,
        companyGroupId,
        handleDownloadProgress,
      );
    },
    getTaxesRemittanceTemplate() {
      return getTaxesRemittanceTemplateService.getTaxesRemittanceTemplate();
    },
    getProviderRemittanceTemplate() {
      return getProviderRemittanceTemplateService.getProviderRemittanceTemplate();
    },
    importTaxesRemittanceFile(file, uploadProgressCallback) {
      return importTaxesRemittanceFileService.importTaxesRemittanceFile(
        file,
        uploadProgressCallback,
      );
    },
    importProviderRemittanceFile(file, uploadProgressCallback) {
      return importProviderRemittanceFileService.importProviderRemittanceFile(
        file,
        uploadProgressCallback,
      );
    },
    generatePayrollRemittanceFile(
      formValues,
      selectedAcccounts,
      downloadProgressCallback,
    ) {
      return generatePayrollRemittanceFileService.generatePayrollRemittanceFile(
        formValues,
        selectedAcccounts,
        downloadProgressCallback,
      );
    },
    generateAttachmentRpa(accountPayableListItem) {
      return genAttachmentRpaSvc.generateAttachmentRpa(accountPayableListItem);
    },
    generateAttachmentVariableAdditional(accountPayableListItem) {
      return genAttachmentVariableAdditionalvc.generateAttachmentVariableAdditional(
        accountPayableListItem,
      );
    },
    fetchRpaParamOptions() {
      return fetchRpaParamOptionsSvc.fetchRpaParamOptions();
    },
    listAccountPayableFees(companyGroupId, accountPayableListItem) {
      return listAccountPayableFeesSvc.listAccountPayableFees(
        companyGroupId,
        accountPayableListItem,
      );
    },
    deleteGeneratedFees(accountPayableId) {
      return delGeneratedFeesSvc.deleteGeneratedFees(accountPayableId);
    },
    generateFeeRetention(generateFeeRetention) {
      return genFeeRetentionSvc.generateFeeRetention(generateFeeRetention);
    },
    getSafraPayrollRemittanceTemplate() {
      return getSafraPayrollRemittanceTemplateService.getSafraPayrollRemittanceTemplate();
    },
    importSafraPayrollRemittanceFile(file, uploadProgressCallback) {
      return importSafraPayrollRemittanceFileService.importSafraPayrollRemittanceFile(
        file,
        uploadProgressCallback,
      );
    },
    exportSheetFusp(exportSheeFuspEntity) {
      return exportSheetFuspSvc.exportSheetFusp(exportSheeFuspEntity);
    },
    fetchLastFuspSheetExported() {
      return fetchLastFuspSheetExportedSvc.fetchLastFuspSheetExported();
    },
    listExportedFuspAccounts(companyGroupId, payload) {
      return listExportedFuspAccountsSvc.listExportedFuspAccounts(
        companyGroupId,
        payload,
      );
    },
    sendAccountPayableToFusp(payload) {
      return sendAccountPayableToFuspSvc.sendAccountPayableToFusp(payload);
    },
    sendAccountPayableToFuspByIds(fuspAccountPayableIntegrationEntities) {
      return sendAccountPayableToFuspByIdsSvc.sendAccountPayableToFuspByIds(
        fuspAccountPayableIntegrationEntities,
      );
    },
    getSafraProviderRemittanceTemplate() {
      return getSafraProviderRemittanceTemplateService.getSafraProviderRemittanceTemplate();
    },
    importSafraProviderRemittanceFile(file, uploadProgressCallback) {
      return importSafraProviderFileService.importSafraProviderRemittanceFile(
        file,
        uploadProgressCallback,
      );
    },
    getSafraTaxesRemittanceTemplate() {
      return getSafraTaxesRemittanceTemplateService.getSafraTaxesRemittanceTemplate();
    },
    importSafraTaxesRemittanceFile(file, uploadProgressCallback) {
      return importSafraTaxesFileService.importSafraTaxesRemittanceFile(
        file,
        uploadProgressCallback,
      );
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    searchProvider(params) {
      return searchProviderService.searchProvider(params);
    },
    removeBulkTerminateAccounts(accountPayableIds) {
      return removeBulkTerminateAccountsService.removeBulkTerminateAccounts(
        accountPayableIds,
      );
    },
    fetchCommercialProposalEnums() {
      return fetchCommercialProposalEnumsService.fetchCommercialProposalEnums();
    },
    generateCommercialProposalAttachment(params) {
      return generateCommercialProposalAttachmentService.generateCommercialProposalAttachment(
        params,
      );
    },
    generatePaymentToSuppliersFile(
      formValues,
      selectedAccounts,
      downloadProgressCallback,
    ) {
      return generatePaymentToSuppliersRemittanceFileService.generatePaymentToSuppliersFile(
        formValues,
        selectedAccounts,
        downloadProgressCallback,
      );
    },
    verifyHasGeneratedRemittances(selectedAccounts) {
      return verifyHasGeneratedRemittancesService.verifyHasGeneratedRemittances(
        selectedAccounts,
      );
    },
  };
}
