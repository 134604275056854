import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserLocal } from "../../../../core/presentation/hooks/useUserLocal";
import { useAzureB2CAuth } from "../../hooks/useAzureB2CAuth";

export function Auth() {
  const navigate = useNavigate();
  const { setUser } = useUserLocal();

  const { handleAuthRedirect, login } = useAzureB2CAuth();

  useEffect(() => {
    const processAuth = async () => {
      const result = await handleAuthRedirect();

      if (result.success) {
        if (result.isAuthenticated && result.user) {
          setUser(result.user);
          navigate("/dashboard");
        } else {
          login();
        }
      } else {
        navigate("/unauthorized");
      }
    };

    processAuth();
  }, [handleAuthRedirect, login, navigate, setUser]);

  return <div />;
}

export function AuthFactory() {
  return <Auth />;
}
