import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ICheckEmailAlreadyExistsUserB2cContract } from "../../domain/contracts/checkEmailAlreadyExistsUserB2cContract";
import {
  CheckEmailAlreadyExistsResponseModel,
  ICheckEmailAlreadyExistsResponseModel,
} from "../models/checkEmailAlreadyExistsModel";

export class CheckEmailAlreadyExistsUserB2cService
  implements ICheckEmailAlreadyExistsUserB2cContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async checkEmailAlreadyExistsUserB2c(
    email: string,
  ): Promise<ICheckEmailAlreadyExistsResponseModel> {
    const userEntity = this.getUserLocalService.get();
    const url = `/UsersB2C/ValidateEmail/${email}`;

    const response = await this.api.get<ICheckEmailAlreadyExistsResponseModel>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return new CheckEmailAlreadyExistsResponseModel(response);
  }
}
