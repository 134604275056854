import { ServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { DataTableFilterMatchModeType } from "../../../simpleTable/domain/entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleCustomColumn,
  ISimpleHiddenColumn,
  ISimpleSearchableColumn,
  ISimpleSortableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { EColumnFilterType } from "../../data/models/panoramaAngularModel";

export const EMPTY_OPTION = [
  {
    rawValue: "EMPTY",
    label: "Nenhum registro encontrado",
  },
];

export enum EColumnType {
  date,
  decimal, // porcentagem
  enum,
  numeric, // monetario
  relationship,
  text,
  integer, // numero inteiro
}

export const columnTypeMap: Record<EColumnType, EColumnFilterType> = {
  [EColumnType.date]: EColumnFilterType.Date,
  [EColumnType.decimal]: EColumnFilterType.Decimal,
  [EColumnType.enum]: EColumnFilterType.Enum,
  [EColumnType.numeric]: EColumnFilterType.Numeric,
  [EColumnType.relationship]: EColumnFilterType.Relationship,
  [EColumnType.text]: EColumnFilterType.Text,
  [EColumnType.integer]: EColumnFilterType.Text,
};

export type FilterDateValue = [Date | null, Date | null];

export interface IRelationshipFilterOption<T = object> {
  rawValue: string | number;
  label: string;
  metadata?: T;
}

export class RelationshipFilterOption<T = object>
  implements IRelationshipFilterOption<T>
{
  rawValue = "";
  label = "";
  metadata = undefined;

  constructor(params?: Partial<IRelationshipFilterOption<T>>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<IRelationshipFilterOption>) {
    return new RelationshipFilterOption(params);
  }
}

export type TFilterValue =
  | null
  | string
  | number
  | Array<number | string>
  | FilterDateValue
  | RelationshipFilterOption[];

export type ColumnFilterOperationType =
  | ""
  | "Between"
  | "Contains"
  | "Equal"
  | "EqualAbsolute"
  | "NotEqual"
  | "NotContains"
  | "NotNullOrEmpty"
  | "NullOrEmpty";

export const columnFilterOperationTypeDict: Record<
  ColumnFilterOperationType,
  DataTableFilterMatchModeType
> = {
  "": "equals",
  Between: "between",
  Contains: "contains",
  Equal: "equals",
  EqualAbsolute: "custom",
  NotContains: "notContains",
  NotEqual: "notEquals",
  NotNullOrEmpty: "custom",
  NullOrEmpty: "custom",
};

export interface IColumnData {
  field: string;
  searchField?: string;
  sortField?: string;
  columnType?: EColumnType;
}

export interface IColumnFilterData {
  column: IColumnData;
  operation: ColumnFilterOperationType;
  value: TFilterValue;
}

export interface IColumnFilterDataEntity {
  operation: ColumnFilterOperationType;
  value?: unknown | null | undefined;
}

export interface ITypedColumn extends ISimpleColumn {
  columnType: EColumnType;
  filterData?: IColumnFilterDataEntity | null | undefined;
}

export interface IEnumColumnEntity extends ITypedColumn {
  enumObject: object;
  enumLang: object;
}

export interface IRelationshipColumnEntity extends ITypedColumn {
  getList?(
    search: string,
    fetchMinLength: number,
  ): Promise<ServerSideResponseModel<IRelationshipFilterOption[]>>;
}

export interface IResizeableColumn extends ITypedColumn {
  resizeable?: boolean;
}

export type IAdvTableColumn =
  | ISimpleColumn
  | ISimpleHiddenColumn
  | ISimpleSearchableColumn
  | ISimpleCustomColumn
  | ITypedColumn
  | IEnumColumnEntity
  | IRelationshipColumnEntity
  | IResizeableColumn
  | ISimpleSortableColumn;

export class AdvancedColumn
  implements
    ISimpleHiddenColumn,
    ISimpleSearchableColumn,
    ISimpleCustomColumn,
    ITypedColumn,
    ISimpleSortableColumn,
    IResizeableColumn,
    IRelationshipColumnEntity
{
  field = "";
  hidden = false;
  searchable = false;
  searchField = "";
  search = { regex: false, value: "" };
  header = "";
  width = undefined;
  searchParam = undefined;
  className = undefined;
  bodyTemplate = undefined;
  columnType = EColumnType.text;
  filterData = undefined;
  orderable = false;
  sortField = "";
  resizeable = true;

  constructor(params?: Partial<IAdvTableColumn>) {
    Object.assign(this, params);
  }
}
