import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetUserB2cContract } from "../../domain/contracts/getUserB2cContract";
import { departmentDestinationProfiles } from "../../domain/entities/departmentDestinationProfiles";
import { UserEntity, IUserEntity } from "../../domain/entities/userEntity";
import { IUserRequestProfilesResponse } from "../models/userModel";

export class GetUserB2cService implements IGetUserB2cContract {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getUserB2c(id: string): Promise<IUserEntity> {
    const userEntity = this.getUserLocalService.get();

    const url = `/UsersB2C/${id}/User`;

    const response = await this.api.get<IUserEntity>(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    const userEntityResponse = new UserEntity(response);

    if (
      departmentDestinationProfiles.includes(Number(userEntityResponse.profile))
    ) {
      const usrRequestProfilesUrl = `/Users/${response?.id}/UserRequestProfiles`;

      const usrRequestProfileResponse =
        await this.api.get<IUserRequestProfilesResponse>(
          usrRequestProfilesUrl,
          {
            headers: {
              Authorization: `Bearer ${userEntity?.token}`,
            },
          },
        );

      userEntityResponse.isFinancial = usrRequestProfileResponse.isFinancial;
      userEntityResponse.isPersonnelDepartment =
        usrRequestProfileResponse.isPersonnelDepartment;
    }

    userEntityResponse.document =
      userEntityResponse.document?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/g,
        "$1.$2.$3-$4",
      ) || "";

    return userEntityResponse;
  }
}
