import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { toast } from "react-toastify";
import { useAzureB2CAuth } from "../../../auth/presentation/hooks/useAzureB2CAuth";
import { IErrorResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import {
  ApiResponseErrorHandlersType,
  IApiError,
} from "../../data/services/apiService";
import { ELocalStorageKeys } from "../../data/utils/localStorageKeys";
import { useSoulDialog } from "./useSoulDialog";
import { useUserLocal } from "./useUserLocal";

export function useApiResponseErrorHandlers(): ApiResponseErrorHandlersType {
  const { user } = useUserLocal();
  const { isAuthenticated, refreshToken, login } = useAzureB2CAuth();
  const { instance } = useMsal();
  const dialog = useSoulDialog();

  function ErrorInternalServerToastContent() {
    return (
      <span>
        Epa... O servidor ficou um pouco confuso agora. 🤔
        <br />
        <small>Tente novamente mais tarde, ou contate a equipe de T.I.</small>
      </span>
    );
  }

  function ErrorGeneralToastContent() {
    return (
      <span>
        Ops... Um erro inesperado aconteceu. Aperte <kbd>F12</kbd> para ver mais
        detalhes. 🔎
      </span>
    );
  }

  interface ErrorBadRequesDialogContentProps {
    errorEntity?: IErrorResponseEntity;
  }

  function ErrorBadRequesDialogContent({
    errorEntity,
  }: ErrorBadRequesDialogContentProps) {
    if (!errorEntity?.Message && !errorEntity?.Details) {
      return <div>{errorEntity}</div>;
    }

    return (
      <div>
        <div>{errorEntity?.Message}.</div>
        <div>
          {errorEntity?.Details?.map(detail => {
            return <p key={detail}>{detail}.</p>;
          })}
        </div>
      </div>
    );
  }

  async function handleUnauthorizedError(
    _: unknown,
    apiError?: IApiError,
  ): Promise<unknown> {
    try {
      await refreshToken();

      if (!isAuthenticated()) {
        await login();
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Falha ao renovar token");
      window.location.href = "/unauthorized";
    }

    if (apiError?.config) {
      const newConfig = { ...apiError.config };
      const token = instance.getActiveAccount()?.idToken;

      newConfig.headers = {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`,
      };

      return axios.request(newConfig);
    }

    return Promise.reject(apiError);
  }

  return {
    [StatusCodes.UNAUTHORIZED]: handleUnauthorizedError,
    [StatusCodes.NOT_FOUND]: () => {
      toast.error(<ErrorInternalServerToastContent />, {
        toastId: "response-status-500",
      });
    },
    [StatusCodes.FORBIDDEN]: () => {
      // UGLY por algum motivo esse dialog abre e fecha automaticamente se nao
      // estiver dentro de um setTimeout, mas precisamos entender qual o erro
      // no lifecycle e encontrar uma forma de resolver sem o setTimeout
      setTimeout(async () => {
        await dialog.fire({
          icon: "error",
          title: "Desculpe",
          html: <>Você não tem permissão para visualizar este lançamento!</>,
        });
      });
    },

    [StatusCodes.BAD_REQUEST]: (errorEntity, errorData, axiosDefaults) => {
      // UGLY por algum motivo esse dialog abre e fecha automaticamente se nao
      // estiver dentro de um setTimeout, mas precisamos entender qual o erro
      // no lifecycle e encontrar uma forma de resolver sem o setTimeout
      setTimeout(async () => {
        // TODO manter enquanto nao resolvemos o problema,
        // remover quando nao for mais necessário Ref.: #13839
        if (
          (errorEntity as unknown as string)?.includes?.(
            "O id do grupo de empresa informado na request é inválido ou não foi passado.",
          )
        ) {
          // sempre que houver um erro 400 estamos logando no google analytics
          // algumas informações para nos ajudar a rastrear o erro
          const dataLayerPayload = {
            event: "invalidCompanyGroupId",
            data: {
              email: user.email,
              dateTime: new Date().toJSON(),
              requestHeaders: errorData?.config.headers,
              contextProviderCompanyGroupId: JSON.parse(
                localStorage.getItem(ELocalStorageKeys.CompanyGroup) || "null",
              )?.id,
              "axiosDefaults.headers": {
                common: axiosDefaults?.headers?.common,
                get: axiosDefaults?.headers?.get,
              },
            },
          };

          // NOTE enquanto nao resolvermos o problema do id do grupo de empresa
          // invalido vamos deixar este console.log com o eslint desabilitado
          // para facilitar o tracking do erro Ref.: #13839
          // eslint-disable-next-line no-console
          console.log(dataLayerPayload);

          // envia para o google analytics
          (window as unknown as { dataLayer: object[] }).dataLayer?.push(
            dataLayerPayload,
          );
        }

        await dialog.fire({
          icon: "error",
          title: "Opa!",
          html: <ErrorBadRequesDialogContent errorEntity={errorEntity} />,
        });
      });
    },

    [StatusCodes.INTERNAL_SERVER_ERROR]: () => {
      toast.error(<ErrorInternalServerToastContent />, {
        toastId: "response-status-500",
      });
    },
    9999: () => {
      toast.error(<ErrorGeneralToastContent />, {
        toastId: "general-error",
      });
    },
  };
}
