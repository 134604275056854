import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  RedirectRequest,
} from "@azure/msal-browser";

const currentPortalURI = process.env.REACT_APP_REDIRECT_AUTH_URL as string;

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_login_soul",
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY as string,
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_B2C_KNOW_AUTHORITIES as string,
};

export const scopes = ["openid", "profile", "offline_access"];

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID as string,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: currentPortalURI,
    postLogoutRedirectUri: currentPortalURI,
    knownAuthorities: [b2cPolicies.authorityDomain],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line
            console.error(message);
            break;

          default:
            break;
        }
      },
    },
  },
};

export const loginSSORequest: RedirectRequest = {
  scopes,
};
