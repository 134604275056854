import styled from "styled-components";

export const Loading = styled.div<{ shouldFadeOut: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 10000;
  position: fixed;
  align-items: center;
  justify-content: center;

  background-color: white;

  animation: fade-out 0.5s 5s ease-in forwards;

  animation-play-state: ${props =>
    props.shouldFadeOut ? "running" : "paused"};

  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }
`;
