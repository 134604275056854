import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;

  .admin-header {
    position: relative;
    padding-bottom: 10rem !important;
    width: 100%;
  }

  @media (min-width: 768px) {
    .admin-header {
      padding-top: 8rem !important;
    }
  }

  @media (min-width: 576px) {
    .admin-header {
      padding-top: 4.5rem !important;
    }
  }

  .bg-gradient-soul {
    background: linear-gradient(90deg, #090081, #445fb5) !important;
  }

  .page-content {
    display: flex;
    padding-left: 2.4375rem;
    padding-right: 2.4375rem;
    margin-top: -10rem;
    position: relative;
    height: 100%;

    button {
      margin: 0 auto;
      padding: 0.5rem;
    }

    header {
      font-weight: 600;
      padding: 1.25rem 1.5rem;
      color: var(--soul-color01);
    }

    article {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding: 3rem 0;

      p {
        width: 23.75rem;

        color: #767681;
        text-align: center;

        font-size: 1.0625rem;
        padding-bottom: 0.5rem;
      }

      p:first-of-type {
        color: #32325d;
        font-weight: 600;
        font-size: 1.25rem;
      }

      img {
        height: 16rem;
      }

      a {
        color: #5e72e4;
        text-decoration: none;
      }
    }
  }
`;
