import { FaRunning } from "react-icons/fa";
import { Page } from "../Page/styles";
import { Container } from "./styles";
import notFoundImg from "../../../../assets/not-found.svg";
import { useAzureB2CAuth } from "../../../../auth/presentation/hooks/useAzureB2CAuth";

export function UnauthorizedPage() {
  const { logoutWhithoutAccount } = useAzureB2CAuth();

  const handleRedirectLogout = async () => {
    await logoutWhithoutAccount();
  };

  return (
    <Container>
      <div className="admin-header bg-gradient-soul" />
      <div className="page-content">
        <Page>
          <header>Não autorizado!</header>
          <article>
            <img src={notFoundImg} alt="401" />

            <p>Você não está autorizado para usar o sistema.</p>
            <button
              type="button"
              onClick={handleRedirectLogout}
              className="default-button"
            >
              <FaRunning />
              <span>Sair</span>
            </button>
          </article>
        </Page>
      </div>
    </Container>
  );
}
