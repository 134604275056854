import { IApiService } from "../../../core/data/services/apiService";
import { DecryptService } from "../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { ListAttachmentTypesService } from "../../../core/data/services/listAttachmentTypesService";
import { SearchUserService } from "../../../core/data/services/searchUserService";
import { IListAttachmentTypesContract } from "../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchUserContract } from "../../../core/domain/contracts/searchUserContract";
import { GetCostCenterTransactionsService } from "../data/services/getCostCenterTransactionsService";
import { GetCostCentersTransactionsImportationTemplateService } from "../data/services/getCostCentersImportationTemplateService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFileByIdService";
import { ListCostCenterTransactionAttachmentsService } from "../data/services/listCostCenterTransactionAttachmentsService";
import { ListCostCenterTransactionsService } from "../data/services/listCostCenterTransactionssService";
import { ListCostCentersService } from "../data/services/listCostCentersService";
import { SaveCostCenterTransactionsService } from "../data/services/saveCostCenterTransactionsService";
import { SaveCostCentersTransactionsImportationService } from "../data/services/saveCostCentersTransactionsImportationService";
import { SearchCostCentersService } from "../data/services/searchCostCentersService";
import { ToggleCostCenterTransactionsService } from "../data/services/toggleCostCenterTransactionsService";
import { UploadCostCenterTransactionAttachmentsService } from "../data/services/uploadCostCenterTransactionAttachmentsService";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFileByIdContract";
import { IListCostCenterTransactionAttachmentsContract } from "../domain/contracts/listCostCenterTransactionAttachmentsContract";
import { IUploadCostCenterTransactionAttachmentsContract } from "../domain/contracts/uploadCostCenterTransactionAttachmentsContract";
import {
  GetCostCenterTransactionUseCase,
  IGetCostCenterTransactionUseCase,
} from "../domain/usecases/getCostCenterTransactionsUseCase";
import {
  GetCostCentersTransactionsImportationTemplateUseCase,
  IGetCostCentersTransactionsImportationTemplateUseCase,
} from "../domain/usecases/getCostCentersTransactionsImportationTemplateUseCase";
import {
  IListCostCenterTransactionsUseCase,
  ListCostCenterTransactionsUseCase,
} from "../domain/usecases/listCostCenterTransactionssUseCase";
import {
  IListCostCentersUseCase,
  ListCostCentersUseCase,
} from "../domain/usecases/listCostCentersUseCase";
import {
  ISaveCostCenterTransactionsUseCase,
  SaveCostCenterTransactionsUseCase,
} from "../domain/usecases/saveCostCenterTransactionsUseCase";
import {
  ISaveCostCentersTransactionsImportationUseCase,
  SaveCostCentersTransactionsImportationUseCase,
} from "../domain/usecases/saveCostCentersTransactionsImportationUseCase";
import {
  ISearchCostCentersUseCase,
  SearchCostCentersUseCase,
} from "../domain/usecases/searchCostCentersUseCase";
import {
  IToggleCostCenterTransactionUseCase,
  ToggleCostCenterTransactionsUseCase,
} from "../domain/usecases/toggleCostCenterTransactionsUseCase";

export type MakeCostCenterTransactions = IGetCostCenterTransactionUseCase &
  IListCostCenterTransactionsUseCase &
  IToggleCostCenterTransactionUseCase &
  IListCostCentersUseCase &
  ISearchUserContract &
  ISearchCostCentersUseCase &
  ISaveCostCenterTransactionsUseCase &
  IGetCostCentersTransactionsImportationTemplateUseCase &
  ISaveCostCentersTransactionsImportationUseCase &
  IListAttachmentTypesContract &
  IGetStorageFilebyIdContract &
  IListCostCenterTransactionAttachmentsContract &
  IUploadCostCenterTransactionAttachmentsContract;

export function makeCostCenterTransactions(
  api: IApiService,
  authApi: IApiService,
): MakeCostCenterTransactions {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getCostCenterTransactionsService = new GetCostCenterTransactionsService(
    getUserLocalService,
    api,
  );
  const getCostCenterTransactionsUseCase = new GetCostCenterTransactionUseCase(
    getCostCenterTransactionsService,
  );

  const listCostCenterTransactionsService =
    new ListCostCenterTransactionsService(getUserLocalService, api);
  const listCostCenterTransactionsUseCase =
    new ListCostCenterTransactionsUseCase(listCostCenterTransactionsService);

  const toggleCostCenterTransactionsService =
    new ToggleCostCenterTransactionsService(getUserLocalService, api);
  const toggleCostCenterTransactionsUseCase =
    new ToggleCostCenterTransactionsUseCase(
      toggleCostCenterTransactionsService,
    );

  const saveCostCenterTransactionsService =
    new SaveCostCenterTransactionsService(getUserLocalService, api);
  const saveCostCenterTransactionsUseCase =
    new SaveCostCenterTransactionsUseCase(saveCostCenterTransactionsService);

  const listCostCentersSvc = new ListCostCentersService(
    getUserLocalService,
    api,
  );
  const listCostCentersUseCase = new ListCostCentersUseCase(listCostCentersSvc);

  const searchUserService = new SearchUserService(getUserLocalService, api);

  const searchCostCentersService = new SearchCostCentersService(
    getUserLocalService,
    api,
  );
  const searchCostCentersUseCase = new SearchCostCentersUseCase(
    searchCostCentersService,
  );

  const getCostCentersTransactionsImportationTemplateService =
    new GetCostCentersTransactionsImportationTemplateService(
      getUserLocalService,
      api,
    );

  const getCostCentersTransactionsImportationTemplateUseCase =
    new GetCostCentersTransactionsImportationTemplateUseCase(
      getCostCentersTransactionsImportationTemplateService,
    );

  const saveCostCentersTransactionsImportationService =
    new SaveCostCentersTransactionsImportationService(getUserLocalService, api);

  const saveCostCentersTransactionsImportationUseCase =
    new SaveCostCentersTransactionsImportationUseCase(
      saveCostCentersTransactionsImportationService,
    );

  const listAttachmentTypeService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listCostCenterTransactionAttachmentsService =
    new ListCostCenterTransactionAttachmentsService(getUserLocalService, api);

  const uploadCostCenterTransactionAttachmentsService =
    new UploadCostCenterTransactionAttachmentsService(getUserLocalService, api);

  return {
    getCostCenterTransaction(CostCenterTransactionsId) {
      return getCostCenterTransactionsUseCase.getCostCenterTransaction(
        CostCenterTransactionsId,
      );
    },
    listCostCenterTransactions(companyGroupId, payload) {
      return listCostCenterTransactionsUseCase.listCostCenterTransactions(
        companyGroupId,
        payload,
      );
    },
    toggleCostCenterTransaction(CostCenterTransactionsId: string) {
      return toggleCostCenterTransactionsUseCase.toggleCostCenterTransaction(
        CostCenterTransactionsId,
      );
    },
    saveCostCenterTransaction(CostCenterTransactions) {
      return saveCostCenterTransactionsUseCase.saveCostCenterTransaction(
        CostCenterTransactions,
      );
    },
    listCostCenters(companyGroupId, actives?) {
      return listCostCentersUseCase.listCostCenters(companyGroupId, actives);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    searchCostCenters(companyGroupId, search?, fetchMinLength?) {
      return searchCostCentersUseCase.searchCostCenters(
        companyGroupId,
        search,
        fetchMinLength,
      );
    },
    getCostCentersTransactionsImportationTemplate() {
      return getCostCentersTransactionsImportationTemplateUseCase.getCostCentersTransactionsImportationTemplate();
    },
    saveCostCentersTransactionsImportation(file) {
      return saveCostCentersTransactionsImportationUseCase.saveCostCentersTransactionsImportation(
        file,
      );
    },
    listAttachmentTypes() {
      return listAttachmentTypeService.listAttachmentTypes();
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listCostCenterTransactionAttachments(costCenterTransactionId) {
      return listCostCenterTransactionAttachmentsService.listCostCenterTransactionAttachments(
        costCenterTransactionId,
      );
    },
    uploadCostCenterTransactionAttachments(params) {
      return uploadCostCenterTransactionAttachmentsService.uploadCostCenterTransactionAttachments(
        params,
      );
    },
  };
}
