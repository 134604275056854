import { useEffect } from "react";
import Modal from "react-modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { AdminFactory } from "./admin/presentation/components/AdminPage";
import { MasterGaurdedRoute } from "./admin/presentation/components/MasterGaurdedRoute";
import { makeAdvTable } from "./advTable/main/makeAdvTable";
import { AuthFactory } from "./auth/presentation/components/AuthPage";
import { makeUserLocal } from "./core/main/makeUserLocal";
import { ForbiddenPage } from "./core/presentation/components/ForbiddenPage";
import { NotFoundPage } from "./core/presentation/components/NotFoundPage";
import { TablesProvider } from "./core/presentation/hooks/useTables";
import { UserLocalProvider } from "./core/presentation/hooks/useUserLocal";
import { GlobalStyle } from "./core/presentation/styles/global";
import { makeSimpleTable } from "./simpleTable/main/makeSimpleTable";
import { AuthProvider } from "./auth/presentation/components/AuthProvider";
import { UnauthorizedPage } from "./core/presentation/components/UnauthorizedPage";

Modal.setAppElement("#root");

export function App() {
  // Ação necessária para evitar que o react conflite life.
  // cicle com as dependências externas da camada de negócio.
  const userLocal = makeUserLocal();
  const simpleTable = makeSimpleTable();
  const advTable = makeAdvTable();

  // Isso é necessário pois temos elementos dinâmicos
  // com tooltip e o ReactTooltip precisa escaneá-los
  ReactTooltip.rebuild();

  useEffect(() => {
    const htmlElement = document.querySelector<HTMLHtmlElement>("html");

    if (htmlElement?.classList.contains("dev-env")) {
      htmlElement?.classList.remove("dev-env");
    }

    // se não for o domínio de produção
    if (!window.location.host.includes("soul.pecege.com"))
      htmlElement?.classList.add("dev-env");
  }, []);

  return (
    <>
      <AuthProvider>
        <UserLocalProvider userLocal={userLocal}>
          <TablesProvider simpleTable={simpleTable} advTable={advTable}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<AuthFactory />} />

                <Route
                  path="*"
                  element={
                    <MasterGaurdedRoute>
                      <AdminFactory />
                    </MasterGaurdedRoute>
                  }
                />

                <Route path="unauthorized" element={<UnauthorizedPage />} />
                <Route path="notFound" element={<NotFoundPage />} />
                <Route path="forbidden" element={<ForbiddenPage />} />
              </Routes>
            </BrowserRouter>
          </TablesProvider>
        </UserLocalProvider>
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        theme="colored"
      />

      <GlobalStyle />
      <ReactTooltip className="app-tooltip" html />
    </>
  );
}
