import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { ListAuthUsersService } from "../../core/data/services/listAuthUsersService";
import { IListAuthUsersContract } from "../../core/domain/contracts/listAuthUsersContract";
import { CheckEmailAlreadyExistsService } from "../data/services/checkEmailAlreadyExistsService";
import { CheckEmailAlreadyExistsUserB2cService } from "../data/services/checkEmailAlreadyExistsUserB2cService";
import { GetUserB2cService } from "../data/services/getUserB2cService";
import { GetUserService } from "../data/services/getUserService";
import { LinkCompanyGroupsService } from "../data/services/linkCompanyGroupsService";
import { ListCompanyGroupsService } from "../data/services/listCompanyGroupsService";
import { ListLinkedCompanyGroupsService } from "../data/services/listLinkedCompanyGroupsService";
import { ListSupervisorCompanyGroupsService } from "../data/services/listSupervisorCompanyGroupsService";
import { ListUserProfilesService } from "../data/services/listUserProfilesService";
import { ListUsersB2cService } from "../data/services/listUsersB2cService";
import { ResetPasswordB2cService } from "../data/services/resetPasswordB2cService";
import { ResetPasswordService } from "../data/services/resetPasswordService";
import { SaveUserB2cService } from "../data/services/saveUserB2cService";
import { SaveUserService } from "../data/services/saveUserService";
import { ToggleUserB2cService } from "../data/services/toggleUserB2cService";
import { ToggleUserService } from "../data/services/toggleUserService";
import { UpdateUserB2cService } from "../data/services/updateUserB2cService";
import { ICheckEmailAlreadyExistsUserB2cContract } from "../domain/contracts/checkEmailAlreadyExistsUserB2cContract";
import { IGetUserB2cContract } from "../domain/contracts/getUserB2cContract";
import { IListSupervisorCompanyGroupsContract } from "../domain/contracts/listCompanyGroupsForSupervisorContract";
import { IListUsersB2cContract } from "../domain/contracts/listUsersB2cContract";
import { IResetPasswordB2cContract } from "../domain/contracts/resetPasswordB2cContract";
import { ISaveUserB2cContract } from "../domain/contracts/saveUserB2cContract";
import { IToggleUserB2cContract } from "../domain/contracts/toggleUserB2cContract";
import { IUpdateUserB2cContract } from "../domain/contracts/updateUserB2cContract";
import {
  CheckEmailAlreadyExistsUseCase,
  ICheckEmailAlreadyExistsUseCase,
} from "../domain/usecases/checkEmailAlreadyExistsUseCase";
import {
  GetUserUseCase,
  IGetUserUseCase,
} from "../domain/usecases/getUserUseCase";
import {
  ILinkCompanyGroupsUseCase,
  LinkCompanyGroupsUseCase,
} from "../domain/usecases/linkCompanyGroupsUseCase";
import {
  IListCompanyGroupsUseCase,
  ListCompanyGroupsUseCase,
} from "../domain/usecases/listCompanyGroupsUseCase";
import {
  IListLinkedCompanyGroupsUseCase,
  ListLinkedCompanyGroupsUseCase,
} from "../domain/usecases/listLinkedCompanyGroupsUseCase";
import {
  IListUserProfilesUseCase,
  ListUserProfilesUseCase,
} from "../domain/usecases/listUserProfilesUseCase";

import {
  IResetUserPasswordUseCase,
  ResetPasswordUseCase,
} from "../domain/usecases/resetPasswordUseCase";
import {
  ISaveUserUseCase,
  SaveUserUseCase,
} from "../domain/usecases/saveUserUseCase";
import {
  IToggleUserUseCase,
  ToggleUserUseCase,
} from "../domain/usecases/toggleUserUseCase";

export type MakeUser = IToggleUserUseCase &
  IGetUserUseCase &
  IGetUserB2cContract &
  ICheckEmailAlreadyExistsUseCase &
  IResetUserPasswordUseCase &
  IListCompanyGroupsUseCase &
  IListLinkedCompanyGroupsUseCase &
  ILinkCompanyGroupsUseCase &
  IListUserProfilesUseCase &
  ISaveUserUseCase &
  ISaveUserB2cContract &
  IUpdateUserB2cContract &
  IListSupervisorCompanyGroupsContract &
  IListAuthUsersContract &
  IListUsersB2cContract &
  IResetPasswordB2cContract &
  IToggleUserB2cContract &
  ICheckEmailAlreadyExistsUserB2cContract;

export function makeUser(soulApi: IApiService, authApi: IApiService): MakeUser {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const toggleUserService = new ToggleUserService(getUserLocalService, authApi);
  const toggleUserUseCase = new ToggleUserUseCase(toggleUserService);

  const saveUserService = new SaveUserService(
    getUserLocalService,
    authApi,
    soulApi,
  );
  const saveUserUseCase = new SaveUserUseCase(saveUserService);

  const getUserService = new GetUserService(
    getUserLocalService,
    authApi,
    soulApi,
  );
  const getUserUseCase = new GetUserUseCase(getUserService);

  const checkEmailAlreadyExistsService = new CheckEmailAlreadyExistsService(
    getUserLocalService,
    authApi,
  );
  const checkEmailAlreadyExistsUseCase = new CheckEmailAlreadyExistsUseCase(
    checkEmailAlreadyExistsService,
  );

  const resetPasswordService = new ResetPasswordService(
    getUserLocalService,
    authApi,
  );
  const resetPasswordUseCase = new ResetPasswordUseCase(resetPasswordService);

  const listCompanyGroupsService = new ListCompanyGroupsService(
    getUserLocalService,
    soulApi,
  );
  const listCompanyGroupsUseCase = new ListCompanyGroupsUseCase(
    listCompanyGroupsService,
  );

  const listLinkedCompanyGroupsService = new ListLinkedCompanyGroupsService(
    getUserLocalService,
    soulApi,
  );
  const listLinkedCompanyGroupsUseCase = new ListLinkedCompanyGroupsUseCase(
    listLinkedCompanyGroupsService,
  );

  const linkCompanyGroupsService = new LinkCompanyGroupsService(
    getUserLocalService,
    soulApi,
  );
  const linkCompanyGroupsUseCase = new LinkCompanyGroupsUseCase(
    linkCompanyGroupsService,
  );

  const listUserProfilesSvc = new ListUserProfilesService(
    getUserLocalService,
    soulApi,
  );
  const listUserProfilesUseCase = new ListUserProfilesUseCase(
    listUserProfilesSvc,
  );

  const listSupervisorCompanyGroupsService =
    new ListSupervisorCompanyGroupsService(getUserLocalService, soulApi);

  const listUsersService = new ListAuthUsersService(
    getUserLocalService,
    soulApi,
  );

  const listUsersB2cService = new ListUsersB2cService(
    getUserLocalService,
    soulApi,
  );

  const resetPasswordB2cService = new ResetPasswordB2cService(
    getUserLocalService,
    soulApi,
  );

  const getUserB2cService = new GetUserB2cService(getUserLocalService, soulApi);
  const saveUserB2cService = new SaveUserB2cService(
    getUserLocalService,
    soulApi,
  );
  const updateUserB2cService = new UpdateUserB2cService(
    getUserLocalService,
    soulApi,
  );

  const toggleUserB2cService = new ToggleUserB2cService(
    getUserLocalService,
    soulApi,
  );

  const checkEmailAlreadyExistsUserB2cService =
    new CheckEmailAlreadyExistsUserB2cService(getUserLocalService, soulApi);

  return {
    toggleUser(userId) {
      return toggleUserUseCase.toggleUser(userId);
    },
    getUser(userId) {
      return getUserUseCase.getUser(userId);
    },
    saveUser(user) {
      return saveUserUseCase.saveUser(user);
    },
    checkEmailAlreadyExists(checkEmailAlreadyExistPayloadModel) {
      return checkEmailAlreadyExistsUseCase.checkEmailAlreadyExists(
        checkEmailAlreadyExistPayloadModel,
      );
    },
    resetUserPassword(userId) {
      return resetPasswordUseCase.resetUserPassword(userId);
    },
    listCompanyGroups(payload) {
      return listCompanyGroupsUseCase.listCompanyGroups(payload);
    },
    listLinkedCompanyGroups(userId) {
      return listLinkedCompanyGroupsUseCase.listLinkedCompanyGroups(userId);
    },
    linkCompanyGroups(userId, companyGroups) {
      return linkCompanyGroupsUseCase.linkCompanyGroups(userId, companyGroups);
    },
    listUserProfiles() {
      return listUserProfilesUseCase.listUserProfiles();
    },
    listSupervisorCompanyGroups(payload) {
      return listSupervisorCompanyGroupsService.listSupervisorCompanyGroups(
        payload,
      );
    },
    listAuthUsers(options) {
      return listUsersService.listAuthUsers(options);
    },
    listUsersB2c(params) {
      return listUsersB2cService.listUsersB2c(params);
    },
    resetPasswordB2c(id) {
      return resetPasswordB2cService.resetPasswordB2c(id);
    },
    getUserB2c(id) {
      return getUserB2cService.getUserB2c(id);
    },
    saveUserB2c(payload) {
      return saveUserB2cService.saveUserB2c(payload);
    },
    updateUserB2c(payload) {
      return updateUserB2cService.updateUserB2c(payload);
    },
    toggleUserB2c(id, toActivate) {
      return toggleUserB2cService.toggleUserB2c(id, toActivate);
    },
    checkEmailAlreadyExistsUserB2c(email) {
      return checkEmailAlreadyExistsUserB2cService.checkEmailAlreadyExistsUserB2c(
        email,
      );
    },
  };
}
